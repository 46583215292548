<template>
  <div class="prize-create">
    <VhallDialog
      :title="`${title}奖品`"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="480px"
      custom-class="prizeBox"
    >
      <vh-form
        :model="prizeForm"
        :rules="rules"
        ref="prizeForm"
        label-width="92px"
        onsubmit="return false;"
      >
        <vh-form-item label="奖品类型" required>
          <vh-radio-group v-model="prizeForm.award_type" @change="changeType" class="coupon_method">
            <vh-radio :label="0">自定义奖品</vh-radio>
            <vh-radio :label="1" v-if="isInWebinar && !enableThirdCouponAndOrder">
              优惠券奖品
            </vh-radio>
            <vh-radio :label="3">三方奖品</vh-radio>
          </vh-radio-group>
          <div class="couponInfo" v-if="prizeForm.award_type == 1 && !prizeForm.coupon_id">
            <vh-button
              size="medium"
              type="default"
              round
              plain
              @click="choseCoupon"
              v-preventReClick
            >
              添加优惠券
            </vh-button>
          </div>
        </vh-form-item>
        <vh-form-item
          label="优惠券"
          required
          v-if="isInWebinar && prizeForm.award_type == 1 && prizeForm.coupon_id"
        >
          <div class="couponInfo">
            <div class="coupon">{{ prizeForm.coupon_name }}</div>
            <div class="highLight" @click="choseCoupon">更换</div>
          </div>
        </vh-form-item>
        <vh-form-item label="三方奖品ID" prop="third_lottery_id" v-if="prizeForm.award_type === 3">
          <vh-input
            v-model.trim="prizeForm.third_lottery_id"
            :maxlength="50"
            autocomplete="off"
            show-word-limit
          ></vh-input>
        </vh-form-item>
        <vh-form-item label="图片上传" required>
          <upload
            class="giftUpload"
            v-model="prizeForm.img_path"
            :domain_url="prizeForm.img_path"
            :on-success="prizeLoadSuccess"
            :on-progress="uploadProcess"
            :on-error="uploadError"
            :on-preview="uploadPreview"
            @delete="deleteImg"
            :widthImg="231"
            :heightImg="130"
            :before-upload="beforeUploadHandler"
          >
            <div slot="tip">
              <p>建议尺寸：240*240px，小于2M</p>
              <p>支持jpg、gif、png、bmp</p>
            </div>
          </upload>
        </vh-form-item>
        <vh-form-item label="奖品名称" prop="prize_name">
          <vh-input
            v-model="prizeForm.prize_name"
            :maxlength="10"
            autocomplete="off"
            show-word-limit
          ></vh-input>
        </vh-form-item>
        <vh-form-item label="奖品描述" prop="prize_desc">
          <vh-input
            v-model="prizeForm.prize_desc"
            :maxlength="70"
            autocomplete="off"
            type="textarea"
            show-word-limit
            :rows="3"
          ></vh-input>
        </vh-form-item>
        <vh-form-item v-if="isInWebinar" label="奖品数量" prop="quantity">
          <vh-input
            v-model.number="prizeForm.quantity"
            oninput="this.value=this.value.replace(/[^\d]/g, '')"
            autocomplete="off"
          ></vh-input>
        </vh-form-item>
      </vh-form>
      <span slot="footer">
        <vh-button
          type="info"
          size="medium"
          @click.prevent.stop="dialogVisible = false"
          plain
          round
        >
          取消
        </vh-button>
        <vh-button
          size="medium"
          type="primary"
          @click="surePrize"
          round
          :disabled="
            isInWebinar
              ? !(
                  prizeForm.quantity &&
                  prizeForm.prize_name &&
                  (prizeForm.award_type == 0 ||
                    (prizeForm.award_type == 1 && prizeForm.coupon_id) ||
                    prizeForm.award_type === 3)
                )
              : !prizeForm.prize_name
          "
          v-preventReClick
        >
          确定
        </vh-button>
      </span>
    </VhallDialog>
    <VhallDialog
      title="选择奖品"
      :visible.sync="dialogPrizeVisible"
      :close-on-click-modal="false"
      :before-close="handleClose"
      style="overflow: hidden"
      custom-class="choose-gift"
      width="660px"
    >
      <div class="prizeList">
        <div class="search" v-show="total || isSearch">
          <vh-input
            round
            v-model="keyword"
            class="search-dialog-tag"
            placeholder="请输入奖品名称"
            style="width: 220px"
            @keyup.enter.native="inputChange"
            @clear="inputChange"
            clearable
          >
            <i
              slot="prefix"
              class="vh-icon-search vh-input__icon"
              @click="inputChange"
              style="cursor: pointer; line-height: 36px"
            ></i>
          </vh-input>
        </div>
        <div v-show="total" class="material-box">
          <vh-scrollbar style="height: 100%" v-loadMore="moreLoadData">
            <div class="prize">
              <div
                class="prize-item"
                v-for="(item, index) in list"
                :key="index"
                :class="item.isChecked ? 'active' : ''"
                @click.stop="choisePrize(item)"
              >
                <span class="prize-img"><img :src="item.img_path" alt="" /></span>
                <div class="prize-title">
                  <h1>{{ item.prize_name }}</h1>
                  <p>{{ item.prize_id }}</p>
                  <p class="award-type-desc">{{ item.awardTypeDesc }}</p>
                </div>
                <label class="img-tangle" v-show="item.isChecked">
                  <img src="../../../../common/images/icon-choose.png" alt="" />
                </label>
              </div>
            </div>
          </vh-scrollbar>
        </div>
        <div class="no-live" v-show="!total">
          <noData :nullType="nullText" :text="text" :height="50">
            <vh-button
              type="primary"
              v-if="nullText == 'nullData'"
              round
              @click.prevent.stop="createPrize"
              v-preventReClick
            >
              创建奖品
            </vh-button>
          </noData>
        </div>
        <div class="prize-footer">
          <div class="prize-check" v-show="total || isSearch">
            <span>
              当前选中
              <b>{{ checkedList.length }}</b>
              件奖品
            </span>
          </div>
          <div class="dialog-footer" v-show="total || isSearch">
            <vh-button
              type="info"
              size="medium"
              @click.prevent.stop="dialogPrizeVisible = false"
              v-preventReClick
              round
              plain
            >
              取消
            </vh-button>
            <vh-button
              size="medium"
              type="primary"
              @click="sureChoisePrize"
              v-preventReClick
              round
              :disabled="!checkedList.length"
            >
              确定
            </vh-button>
          </div>
        </div>
      </div>
    </VhallDialog>
    <VhallDialog
      title="提示"
      :visible.sync="dialogTongVisible"
      :close-on-click-modal="false"
      :show-close="false"
      class="zdy-async-dialog"
      width="400px"
    >
      <div class="async__body">
        <div class="async__ctx">
          <p>保存奖品同时共享至资料管理，便于其他活动使用？</p>
          <vh-checkbox v-model="sureChecked">共享到资料管理</vh-checkbox>
        </div>
        <div class="async__footer">
          <vh-button type="info" size="medium" @click="cancelMaterialPrize" round plain>
            取消
          </vh-button>
          <vh-button type="primary" size="medium" v-preventReClick @click="sureMaterialPrize" round>
            确定
          </vh-button>
        </div>
      </div>
    </VhallDialog>
    <ChoseCoupon
      ref="choseCoupon"
      choseType="radio"
      scene="lottery"
      @selected="selectedCoupon"
    ></ChoseCoupon>
  </div>
</template>
<script>
  import upload from '@/components/Upload/main';
  import noData from '@/views/PlatformModule/Error/nullPage';
  import regRule from '@/utils/reg-rule.js';
  import { isEmbed } from '../../../../utils/utils';
  import ChoseCoupon from '../../../MaterialModule/Products/choseCoupon.vue';
  const award_type_desc = {
    0: '自定义奖品',
    1: '优惠券奖品',
    3: '三方奖品'
  };
  export default {
    name: 'createPrize',
    data() {
      return {
        dialogVisible: false,
        dialogPrizeVisible: false,
        dialogTongVisible: false,
        keyword: '',
        sureChecked: true,
        checkedList: [],
        maxPage: 0,
        total: 0,
        nullText: 'nullData',
        isSearch: false,
        text: '您还没有奖品，快来创建吧！',
        prizePageInfo: {
          pos: 0,
          limit: 6,
          page: 1
        },
        prizeForm: {
          source: 1,
          img_path: '',
          prize_name: '',
          quantity: '',
          prize_desc: '',
          award_type: 0,
          coupon_id: '',
          coupon_name: '',
          third_lottery_id: ''
        },
        prizeFormCache: {
          0: {
            // 自定义奖品
            source: 1,
            img_path: '',
            prize_name: '',
            quantity: '',
            prize_desc: '',
            award_type: 0
          },
          1: {
            // 优惠券奖品
            source: 1,
            img_path: '',
            prize_name: '',
            quantity: '',
            prize_desc: '',
            award_type: 1,
            coupon_id: '',
            coupon_name: ''
          },
          3: {
            // 三方奖品
            source: 1,
            img_path: '',
            prize_name: '',
            quantity: '',
            prize_desc: '',
            award_type: 3,
            third_lottery_id: ''
          }
        },
        rules: {
          prize_name: [{ required: true, message: '请输入奖品名称', trigger: 'blur' }],
          quantity: [{ required: true, message: '请输入奖品数量', trigger: 'blur' }],
          third_lottery_id: [{ required: true, message: '请输入三方奖品ID', trigger: 'blur' }]
        },
        list: [],
        title: '创建'
        // cacheType0: {
        //   source: 1,
        //   img_path: '',
        //   prize_name: '',
        //   quantity: '',
        //   prize_desc: '',
        //   award_type: 0,
        //   coupon_id: '',
        //   coupon_name: ''
        // },
        // cacheType1: {
        //   source: 1,
        //   img_path: '',
        //   prize_name: '',
        //   quantity: '',
        //   prize_desc: '',
        //   award_type: 0,
        //   coupon_id: '',
        //   coupon_name: ''
        // }
      };
    },
    computed: {
      isInWebinar() {
        return this.$parent.source == 0;
      },
      // 是否显示优惠券列表和订单列表
      enableThirdCouponAndOrder() {
        // 开启了用户账号下的商品权限 & 且未开启使用三方的优惠券和订单
        if (this.isInWebinar) {
          return (
            this.$store.state.webinarPermission?.['product_show'] == 0 ||
            this.$store.state.webinarPermission?.['enable_third_coupon_and_order'] == 1
          );
        } else {
          return (
            this.$store.state.permission?.['product_show'] == 0 ||
            this.$store.state.permission?.['enable_third_coupon_and_order'] == 1
          );
        }
      }
    },
    props: ['prizeInfo', 'liveTotal'],
    components: {
      upload,
      noData,
      ChoseCoupon
    },
    watch: {
      dialogVisible() {
        // this.cacheType0 = {
        //   source: this.$parent.source,
        //   img_path: '',
        //   prize_name: '',
        //   quantity: '',
        //   prize_desc: '',
        //   award_type: 0,
        //   coupon_id: '',
        //   coupon_name: ''
        // };
        // this.cacheType1 = {
        //   source: this.$parent.source,
        //   img_path: '',
        //   prize_name: '',
        //   quantity: '',
        //   prize_desc: '',
        //   award_type: 0,
        //   coupon_id: '',
        //   coupon_name: ''
        // };

        if (this.dialogVisible) {
          this.initData();
        }
      },
      dialogPrizeVisible() {
        if (this.dialogPrizeVisible) {
          this.list = [];
          this.checkedList = [];
          this.keyword = '';
          this.prizePageInfo = {
            pos: 0,
            limit: 6,
            page: 1
          };
          this.getPrizeList();
        } else {
          this.list = [];
        }
      }
    },
    methods: {
      initData() {
        if (this.prizeInfo.prize_id) {
          this.$set(
            this.prizeForm,
            'img_path',
            this.prizeInfo.img_path || this.prizeInfo.award_img_url
          );
          this.$set(
            this.prizeForm,
            'prize_name',
            this.prizeInfo.prize_name || this.prizeInfo.award_name
          );
          this.$set(
            this.prizeForm,
            'prize_desc',
            this.prizeInfo.prize_desc || this.prizeInfo.award_desc
          );
          this.$set(this.prizeForm, 'coupon_id', this.prizeInfo.coupon_id || '');
          this.$set(this.prizeForm, 'award_type', parseInt(this.prizeInfo.award_type + '') || 0);
          this.$set(this.prizeForm, 'quantity', this.prizeInfo.quantity || '');
          this.$set(this.prizeForm, 'prize_id', this.prizeInfo.prize_id || this.prizeInfo.award_id);
          this.$set(this.prizeForm, 'source', this.$parent.source);
          this.$set(this.prizeForm, 'third_lottery_id', this.prizeInfo.third_lottery_id || '');

          if (this.prizeInfo.award_type === 1) {
            //优惠券奖品
            this.$nextTick(() => {
              this.getCouponInfo();
            });
          } else {
            // this.cacheType0 = this.prizeForm;
            this.prizeFormCache[this.prizeInfo.award_type] = this.prizeForm;
          }
          this.title = '编辑';
        } else {
          this.prizeResetForm();
          this.title = '创建';
        }
      },
      handleClose(done) {
        this.checkedList = [];
        this.prizePageInfo.page = 1;
        done();
      },
      prizeResetForm() {
        this.prizeForm = {
          source: this.$parent.source,
          img_path: '',
          prize_name: '',
          quantity: '',
          coupon_id: '',
          coupon_name: '',
          award_type: 0,
          third_lottery_id: ''
        };
        this.prizeFormCache = {
          0: {
            // 自定义奖品
            source: 1,
            img_path: '',
            prize_name: '',
            quantity: '',
            prize_desc: '',
            award_type: 0
          },
          1: {
            // 优惠券奖品
            source: 1,
            img_path: '',
            prize_name: '',
            quantity: '',
            prize_desc: '',
            award_type: 1,
            coupon_id: '',
            coupon_name: ''
          },
          3: {
            // 三方奖品
            source: 1,
            img_path: '',
            prize_name: '',
            quantity: '',
            prize_desc: '',
            award_type: 3,
            third_lottery_id: ''
          }
        };
      },
      surePrize() {
        if (this.isInWebinar) {
          if (
            !(
              this.prizeForm.quantity &&
              this.prizeForm.prize_name &&
              (this.prizeForm.award_type === 0 ||
                (this.prizeForm.award_type === 1 && this.prizeForm.coupon_id) ||
                this.prizeForm.award_type === 3)
            )
          ) {
            return;
          }

          if (this.prizeForm.award_type === 1 && !this.prizeForm.coupon_id) {
            this.$vhMessage({
              message: `请选择优惠券`,
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
            return;
          }
        }
        if (!this.prizeForm.img_path) {
          this.$vhMessage({
            message: `请上传图片`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return;
        }
        const pattern = regRule.num8; // 正整数的正则表达式
        let value = this.prizeForm.quantity;
        if (this.isInWebinar) {
          if (!value || !pattern.test(value)) {
            this.$vhMessage({
              message: `请输入正整数`,
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
            return;
          }
        }
        this.$refs.prizeForm.validate(valid => {
          if (valid) {
            if (this.$parent.source == 1) {
              this.materialPrize();
            } else {
              // 嵌入页直接保存即可
              if (
                this.title === '创建' &&
                !isEmbed() &&
                [0, 3].includes(this.prizeForm.award_type)
              ) {
                this.dialogTongVisible = true;
              } else {
                this.liveSurePrize(0);
              }
            }
          } else {
            return false;
          }
        });
      },
      // 同步资料库的保存
      sureMaterialPrize() {
        if (this.sureChecked) {
          this.$vhall_paas_port({
            k: 100330,
            data: {
              business_uid: this.$parent.userId,
              user_id: '',
              webinar_id: this.$route.params.str,
              refer: '',
              s: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
          this.liveSurePrize(1);
          this.dialogTongVisible = false;
        } else {
          // 不保存资料库
          this.liveSurePrize(0);
          this.$vhall_paas_port({
            k: 100331,
            data: {
              business_uid: this.$parent.userId,
              user_id: '',
              webinar_id: this.$route.params.str,
              refer: '',
              s: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
          this.dialogTongVisible = false;
        }
      },
      cancelMaterialPrize() {
        this.dialogTongVisible = false;
        this.sureChecked = false;
        this.liveSurePrize(0);
        this.$vhall_paas_port({
          k: 100331,
          data: {
            business_uid: this.$parent.userId,
            user_id: '',
            webinar_id: this.$route.params.str,
            refer: '',
            s: '',
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
      },
      // 资料库保存奖品
      materialPrize() {
        this.prizeForm.room_id = '';
        this.prizeForm.source = 1;
        this.$fetch('createPrize', this.$params(this.prizeForm))
          .then(res => {
            if (res.code == 200) {
              this.dialogVisible = false;
              this.$vhall_paas_port({
                k: this.title === '编辑' ? 100535 : 100534,
                data: {
                  business_uid: this.$parent.userId,
                  user_id: '',
                  webinar_id: '',
                  refer: '',
                  s: '',
                  report_extra: {},
                  ref_url: '',
                  req_url: ''
                }
              });
              this.$vhMessage({
                message: `资料中心奖品${this.title === '编辑' ? '修改' : '新建'}成功`,
                showClose: true,
                // duration: 0,
                type: 'success',
                customClass: 'zdy-info-box'
              });
              this.$emit('getTableList');
            }
          })
          .catch(res => {
            this.$vhMessage({
              message: res.msg || `资料中心奖品${this.title === '编辑' ? '修改' : '新建'}失败`,
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
            this.dialogVisible = true;
          });
      },
      // 直播下的保存奖品
      liveSurePrize(index) {
        const prizeParams = {
          lottery_id: this.$route.query.lotteryId,
          award_img_url: this.prizeForm.img_path,
          award_name: this.prizeForm.prize_name,
          quantity: this.prizeForm.quantity,
          award_type: this.prizeForm.award_type,
          share_to_library: index,
          webinar_id: this.$route.params.str,
          award_desc: this.prizeForm.prize_desc,
          coupon_id: this.prizeForm.coupon_id,
          third_lottery_id: this.prizeForm.third_lottery_id
        };
        if (this.title === '编辑') {
          prizeParams.award_id = this.prizeInfo.prize_id;
        }
        // console.log(this.$params(prizeParams));
        this.$fetch(this.isInWebinar ? 'saveLotteryAward' : 'createPrize', prizeParams)
          .then(res => {
            if (res.code == 200) {
              this.dialogVisible = false;
              this.$vhall_paas_port({
                k: this.title === '编辑' ? 100325 : 100324,
                data: {
                  business_uid: this.$parent.userId,
                  user_id: '',
                  webinar_id: this.$route.params.str,
                  refer: '',
                  s: '',
                  report_extra: {},
                  ref_url: '',
                  req_url: ''
                }
              });
              this.$vhMessage({
                message: `直播下奖品${this.title === '编辑' ? '修改' : '新建'}成功`,
                showClose: true,
                // duration: 0,
                type: 'success',
                customClass: 'zdy-info-box'
              });
              this.$emit('getTableList');
            }
          })
          .catch(res => {
            this.$vhMessage({
              message: res.msg || `直播下奖品${this.title === '编辑' ? '修改' : '新建'}失败`,
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
            this.dialogVisible = true;
          });
      },
      sureChoisePrize() {
        if (this.liveTotal + this.checkedList.length > 20) {
          this.$vhMessage({
            message: `每个活动最多显示20个奖品，超过20个后无法关联，需要将原有奖品删除`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return;
        }
        let params = {
          lottery_id: this.$route.query.lotteryId,
          award_ids: this.checkedList.join(','),
          webinar_id: this.$route.params.str
        };
        this.$fetch('copyLibraryAward', params)
          .then(res => {
            this.$vhall_paas_port({
              k: 100326,
              data: {
                business_uid: this.$parent.userId,
                user_id: '',
                webinar_id: this.$route.params.str,
                refer: '',
                s: '',
                report_extra: {},
                ref_url: '',
                req_url: ''
              }
            });
            this.$vhMessage({
              message: `选择成功`,
              showClose: true,
              // duration: 0,
              type: 'success',
              customClass: 'zdy-info-box'
            });
            this.dialogPrizeVisible = false;
            this.list.map(item => {
              item.isChecked = false;
            });
            this.list = [];
            this.checkedList = [];
            this.$emit('getTableList');
          })
          .catch(res => {
            this.$vhMessage({
              message: res.msg || `操作失败`,
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          });
      },
      createPrize() {
        this.dialogVisible = true;
        this.dialogPrizeVisible = false;
      },
      inputChange() {
        this.prizePageInfo = {
          pos: 0,
          page: 1,
          limit: 6
        };
        this.checkedList = [];
        this.list = [];
        this.getPrizeList();
      },
      moreLoadData() {
        if (this.prizePageInfo.page >= this.maxPage) {
          return false;
        }
        this.prizePageInfo.page++;
        this.prizePageInfo.pos = parseInt((this.prizePageInfo.page - 1) * this.prizePageInfo.limit);
        this.getPrizeList();
      },
      getPrizeList() {
        let params = {
          keyword: this.keyword,
          source: 1,
          ...this.prizePageInfo
        };
        this.$fetch('getPrizeList', params).then(res => {
          let adList = res.data.list;
          adList.map(item => {
            item.isChecked = false;
            item.awardTypeDesc = award_type_desc[item.award_type];
          });
          this.list.push(...adList);
          this.total = res.data.count;
          if (this.keyword) {
            this.nullText = 'search';
            this.text = '';
            this.isSearch = true;
          } else {
            this.nullText = 'nullData';
            this.text = '您还没有奖品，快来创建吧！';
            this.isSearch = false;
          }
          this.maxPage = Math.ceil(res.data.count / this.prizePageInfo.limit);
        });
      },
      deleteImg() {
        this.prizeForm.img_path = '';
      },
      prizeLoadSuccess(res, file) {
        this.prizeForm.img_path = res.data.domain_url;
      },
      beforeUploadHandler(file) {
        const typeList = ['png', 'jpeg', 'gif', 'bmp'];
        let typeArr = file.type.toLowerCase().split('/');
        const isType = typeList.includes(typeArr[typeArr.length - 1]);
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isType) {
          this.$vhMessage({
            message: `上传奖品图片只能是 ${typeList.join('、')} 格式`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return false;
        }
        if (!isLt2M) {
          this.$vhMessage({
            message: `上传奖品图片大小不能超过 2M`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return false;
        }
        return isType && isLt2M;
      },
      uploadProcess(event, file, fileList) {
        console.log('uploadProcess', event, file, fileList);
      },
      uploadError(err, file, fileList) {
        console.log('uploadError', err, file, fileList);
        this.$vhMessage({
          message: `奖品图片上传失败`,
          showClose: true,
          // duration: 0,
          type: 'error',
          customClass: 'zdy-info-box'
        });
      },
      uploadPreview(file) {
        console.log('uploadPreview', file);
      },
      // cancelPeize() {
      //   this.dialogVisible = false;
      //   this.$refs[prizeForm].resetFields();
      // },
      choisePrize(item) {
        item.isChecked = !item.isChecked;
        this.checkedList = this.list.filter(items => items.isChecked).map(item => item.prize_id);
      },
      getCouponInfo() {
        this.$fetch('getCouponInfo', {
          coupon_id: this.prizeInfo.coupon_id
        }).then(res => {
          if (res.code === 200) {
            this.prizeForm.coupon_name = res.data.coupon_name || '';
            this.prizeInfo.coupon_name = res.data.coupon_name || '';
            // this.cacheType1 = this.prizeForm;
            this.prizeFormCache[this.prizeInfo.award_type] = this.prizeForm;
          }
        });
      },
      choseCoupon() {
        this.$refs.choseCoupon.open();
      },
      selectedCoupon(res) {
        console.log(res);
        if (res.length !== 0) {
          let data = res[0];
          if (!this.prizeForm.prize_desc) {
            this.$set(this.prizeForm, 'prize_desc', data.use_desc.substring(0, 70) || '');
          }
          if (!this.prizeForm.prize_desc) {
            this.$set(this.prizeForm, 'prize_name', data.coupon_name || '');
          }
          this.$set(this.prizeForm, 'coupon_name', data.coupon_name || '');
          this.$set(this.prizeForm, 'coupon_id', data.coupon_id || '');
        }
      },
      changeType(val) {
        // if (this.prizeForm.award_type === 1) {
        //   //优惠券奖品
        //   this.cacheType0 = this.prizeForm;
        //   this.prizeForm = this.cacheType1;
        //   this.prizeForm.award_type = 1;
        // } else {
        //   this.cacheType1 = this.prizeForm;
        //   this.prizeForm = this.cacheType0;
        //   this.prizeForm.award_type = 0;
        // }
        this.prizeForm = this.prizeFormCache[val];
        this.prizeForm.award_type = val;
        this.$nextTick(() => {
          this.$refs.prizeForm.clearValidate();
        });
      }
    }
  };
</script>
<style lang="less" scoped>
  ::v-deep .choose-gift {
    .head-btn.vh-input {
      width: 220px;
      margin-left: 32px;
    }
  }
  .prize-create {
    .prizeList {
      .search {
        margin-bottom: 16px;
        padding: 0 32px;
      }
      .material-box {
        height: 300px;
        margin-bottom: 10px;
      }
      .prize {
        max-height: 300px;
        display: flex;
        padding: 0 32px;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .prize-item {
          width: 256px;
          height: 96px;
          display: flex;
          background: #f7f7f7;
          margin-bottom: 12px;
          border-radius: 4px;
          cursor: pointer;
          position: relative;
          border: 1px solid transparent;
          transition: all 0.15s ease-in;
          .prize-img {
            display: inline-block;
            width: 72px;
            height: 72px;
            margin: 12px;
            border: 1px solid #e6e6e6;
            border-radius: 4px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
            border-radius: 4px;
          }
          .prize-title {
            margin-top: 15px;
            h1 {
              font-weight: 400;
              font-size: 14px;
              color: #1a1a1a;
              line-height: 30px;
            }
            p {
              color: #666;
              // line-height: 30px;
            }

            .award-type-desc {
              line-height: 30px;
            }
          }
          &:hover {
            box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.08), 0px 2px 4px 0px rgba(0, 0, 0, 0.02);
          }
          &.active {
            background: #ffffff;
            box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.08), 0px 2px 4px 0px rgba(0, 0, 0, 0.02);
            border: 1px solid #fb3a32;
          }
          .img-tangle {
            position: absolute;
            right: -1px;
            top: -1px;
            width: 20px;
            height: 20px;
            font-size: 0;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .surePrize {
      padding-bottom: 16px;
      .textPrize {
        padding-left: 50px;
        p {
          font-size: 16px;
          color: #1a1a1a;
          padding-bottom: 15px;
        }
      }
      .dialog-footer {
        text-align: center;
        margin-top: 20px;
      }
    }
    .prize-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 0 0 32px;
      .prize-check {
        span {
          color: #666;
          b {
            color: #fb3a32;
          }
        }
      }
    }
    ::v-deep .vh-scrollbar__wrap {
      overflow-x: hidden;
    }

    .couponInfo {
      display: flex;
      align-items: center;
      .coupon {
        margin-right: 10px;
        max-width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        white-space: nowrap;
      }
      .highLight {
        color: #3562fa;
        cursor: pointer;
      }
    }
  }
</style>
<style lang="less">
  .zdy-async-dialog .async__body .async__footer {
    padding-bottom: 0;
  }
  .prize-create .prizeBox {
    .vh-dialog__body {
      max-height: 420px;
      overflow-y: auto;
      padding: 0 32px 10px;
      box-sizing: content-box;
      .vh-form-item:last-of-type {
        margin-bottom: 0;
      }
    }
    .vh-dialog__footer {
      padding: 14px 32px 24px;
    }
  }
</style>
